.sadpoem {
    font-size: 1.3em;
    font-family: Sadpoem;
}

@font-face {
  font-family: 'Sadpoem';
  src: url('./sadpoem.woff2');
  font-style: normal;
  font-display: swap;
}